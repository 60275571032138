/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */

import { useEffect, useCallback } from "react";
import { useRouter } from "next/router";
import cssVars from "css-vars-ponyfill";
import Color from "color";

import {
  ThemeContext,
  ThemeProvider,
  useConfigurationSelector,
  useConfigurationLoadingSelector,
  IThemeContext,
  ConfigurationStore,
  dispatch,
  DataProvider,
} from "@nf/common";

import dynamic from "next/dynamic";

const TransparentSplash = dynamic(() => import("screens/TransparentSplash"), {
  ssr: false,
});
const PlaceholderSplash = dynamic(
  () => import("components/PlaceholderSplash"),
  { ssr: false }
);

import { isServerSideRender } from "@nf/helpers";

import { useIsLoading } from "helpers/setIsLoadingContext";
import { useSyncWebUser } from "../hooks";
import { DEFAULT_LOCALE } from "@nf/constants";

const themeProvider: ThemeProvider = new ThemeProvider();

interface IRootProps {
  children: JSX.Element;
  config: any;
}

export const Root = ({ children, config }: IRootProps) => {
  const { setIsLoading } = useIsLoading();
  const configurationState = useConfigurationSelector();
  const configurationLoading = useConfigurationLoadingSelector();
  const router = useRouter();
  const { language } = router.query;
  useSyncWebUser();

  const handleSiteResize = () => {
    document.documentElement.style.fontSize = `${themeProvider.getFontSize()}px`;
  };
  useEffect(() => {
    router.events.on("routeChangeComplete", () => setIsLoading(false));
  }, []);

  useEffect(() => {
    DataProvider.setLanguage(config?.Language ?? DEFAULT_LOCALE);
  }, []);

  useEffect(() => {
    if (!configurationState && config) {
      dispatch(ConfigurationStore.Actions.getConfigurationSuccess(config));
    }
  }, [config, configurationState]);

  useEffect(() => {
    window.addEventListener("resize", handleSiteResize);
    return () => {
      window.removeEventListener("resize", handleSiteResize);
    };
  }, [language, router.pathname]);

  const initializeCssVariables = useCallback(() => {
    const cssVariables = {
      "--primary-color": themeProvider.getColor("AppPrimaryColor"),
      "--primary-color-d-50": Color(themeProvider.getColor("AppPrimaryColor"))
        .darken(0.5)
        .hex(),
      "--primary-text-color": themeProvider.getColor("AppPrimaryTextColor"),
      "--primary-text-color-d-30": Color(
        themeProvider.getColor("AppPrimaryTextColor")
      )
        .darken(0.3)
        .hex(),
      "--secondary-color": themeProvider.getColor("AppSecondaryColor"),
      "--secondary-text-color": themeProvider.getColor("AppSecondaryTextColor"),
      "--bg-color": themeProvider.getColor("AppBackgroundColor"),
      "--secondary-background-color": themeProvider.getColor(
        "AppSecondaryBackroundColor"
      ),
      "--bg-color-o-30": Color(themeProvider.getColor("AppBackgroundColor"))
        .fade(0.3)
        .hex(),
      "--button-primary-hover-color": Color(
        themeProvider.getColor("AppPrimaryColor")
      )
        .lighten(0.2)
        .hex(),
      "--button-primary-active-color": Color(
        themeProvider.getColor("AppPrimaryColor")
      )
        .lighten(0.4)
        .hex(),
      "--footer-link-color": themeProvider.getColor("FooterLinkColor"),
      "--footer-link-hover-color": themeProvider.getColor(
        "FooterLinkHoverColor"
      ),
      "--header-link-color": themeProvider.getColor("HeaderLinkColor"),
      "--header-link-hover-color": themeProvider.getColor(
        "HeaderLinkHoverColor"
      ),
      "--font-family": `${themeProvider.getFontFamily()}, Helvetica, Arial, sans-serif`,
      "--cell-background-color": themeProvider.getColor(
        "AppCellsBackgroundColor"
      ),
      "--app-spalsh-background-url": "",
    };

    if (themeProvider.getAppBackgroundUrl()) {
      cssVariables[
        "--app-spalsh-background-url"
      ] = `url(${themeProvider.getAppBackgroundUrl()})`;
    }

    cssVars({
      rootElement: document,
      variables: cssVariables,
    });
  }, [configurationState]);

  const onBrandingChange = useCallback((brandingId?: string) => {
    if (themeProvider.setCurrentBranding(brandingId)) {
      const branding = themeProvider.getBranding();
      if (branding.AppPrimaryColor && branding.AppBackgroundColor) {
        const cssVariables = {
          "--primary-color": branding.AppPrimaryColor,
          "--bg-color": branding.AppBackgroundColor,
        };
        cssVars({
          variables: cssVariables,
        });
      }
      if (branding.AppButtonHoverColor && branding.AppButtonActiveColor) {
        const cssVariables = {
          "--button-primary-hover-color": branding.AppButtonHoverColor,
          "--button-primary-active-color": branding.AppButtonActiveColor,
        };
        cssVars({
          variables: cssVariables,
        });
      }
    }
  }, []);

  useEffect(() => {
    if (!config) {
      return;
    }
    themeProvider.setBrandings(config);
    document.documentElement.style.fontSize = `${themeProvider.getFontSize()}px`;
    const appFontFaces = themeProvider.getFontFaces();

    if (appFontFaces.length > 0) {
      let fontFaces = document.getElementById("font-faces");

      if (!fontFaces) {
        fontFaces = document.createElement("style");
        fontFaces.id = "font-faces";
        fontFaces.innerHTML = "\n";
      }

      for (const fontFace of appFontFaces) {
        const format = fontFace.Url.endsWith(".ttf")
          ? "truetype"
          : fontFace.Url.endsWith(".otf")
          ? "opentype"
          : "truetype";
        fontFaces.innerHTML += `@font-face {
            font-family: '${fontFace.Family}';
            font-style: ${fontFace.Style};
            font-weight: ${fontFace.Weight};
            font-display: ${fontFace.Dispaly};
            src: url(${fontFace.Url}) format('${format}');    
          }\n`;
      }
      document.head.appendChild(fontFaces);
    }

    const appFavIconUrl = themeProvider.getAppFavIconUrl();

    if (appFavIconUrl) {
      let favicon = document.getElementById("favicon") as HTMLLinkElement;
      if (!favicon) {
        favicon = document.createElement("link");
      }
      favicon.id = "favicon";
      favicon.rel = "icon";
      favicon.href = appFavIconUrl;
      favicon.type = "image/x-icon";
      document.head.appendChild(favicon);
    }

    initializeCssVariables();
  }, [config]);

  const themeContext: IThemeContext = {
    themeProvider: themeProvider,
    onBrandingChange: onBrandingChange,
  };

  const contentLoader = () => {
    const isAdditionalData =
      !configurationState || !configurationState?.Brandings;

    if (configurationLoading) {
      return <PlaceholderSplash />;
    } else if (isAdditionalData && !isServerSideRender) {
      return <TransparentSplash />;
    } else {
      return children;
    }
  };

  return (
    <ThemeContext.Provider value={themeContext}>
      {contentLoader()}
    </ThemeContext.Provider>
  );
};

export default Root;
